import { useCallback, useMemo } from "react";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import FlightRoundedIcon from "@mui/icons-material/FlightRounded";
import { Grid, Typography } from "@mui/material";

import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";

import { PortStepData } from ".";

export default function usePortStepData(portStepData: PortStepData) {
  const getStartAndEndAddressColor = (type: "startAddress" | "endAddress") => {
    if (portStepData.serviceType === "consolidation") {
      return "primary";
    }
    if (portStepData[type]) {
      return "primary";
    }
    return "disabled";
  };

  const endAddressName = useMemo(() => {
    if (portStepData.endAddress) {
      return `${getCountryCodeKR(portStepData.endCountry)}내륙`;
    }
    return "-";
  }, [portStepData.endAddress, portStepData.endCountry]);

  const startAddressName = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      return `${portStepData.wareHouse?.name}창고`;
    }
    if (portStepData.startAddress) {
      return `${getCountryCodeKR(portStepData.startCountry)}내륙`;
    }
    return "-";
  }, [
    portStepData.serviceType,
    portStepData.startAddress,
    portStepData.startCountry,
    portStepData.wareHouse?.name,
  ]);

  const startAddressDetailName = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      return portStepData.wareHouse?.addressCN;
    }

    if (portStepData.startAddress) {
      // 수출일 때는 startAddressDetail이 있다.
      if (!portStepData.isImport) {
        return `${portStepData.startAddress}${portStepData.startAddressDetail}`;
      }

      return portStepData.startAddress;
    }
    return "-";
  }, [
    portStepData.isImport,
    portStepData.serviceType,
    portStepData.startAddress,
    portStepData.startAddressDetail,
    portStepData.wareHouse?.addressCN,
  ]);

  const getPortIcon = useCallback(
    (type: "startPort" | "startViaPort" | "endPort" | "endViaPort") => {
      if (portStepData[type]?.type === "air") {
        return <FlightRoundedIcon fontSize="large" color="primary" />;
      }
      return <DirectionsBoatIcon fontSize="large" color="primary" />;
    },
    [portStepData]
  );

  const startPortIcon = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      return getPortIcon("startPort");
    }
    if (
      portStepData.incoterms === "CIF" ||
      portStepData.incoterms === "CFR" ||
      portStepData.incoterms === "FOB" ||
      (portStepData.incoterms === "CPT" &&
        portStepData.startViaPort === null) ||
      (portStepData.incoterms === "FCA" && portStepData.startViaPort === null)
    ) {
      return getPortIcon("startPort");
    }

    return getPortIcon("startViaPort");
  }, [
    portStepData.incoterms,
    portStepData.serviceType,
    portStepData.startViaPort,
    getPortIcon,
  ]);

  const startPortName = useMemo(() => {
    if (
      portStepData.incoterms === "CIF" ||
      portStepData.incoterms === "CFR" ||
      portStepData.incoterms === "FOB" ||
      (portStepData.incoterms === "CPT" && !portStepData.startViaPort) ||
      (portStepData.incoterms === "FCA" && !portStepData.startViaPort) ||
      portStepData.serviceType === "consolidation"
    ) {
      return (
        <Grid item container direction="column" alignItems={"center"}>
          <Grid item>
            <Typography variant="body2" component="div" color="primary">
              {getCountryCodeKR(portStepData.startPort?.country ?? "")}
              {portStepData.startPort?.type === "air" ? "공항" : "항"}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" component="div" color="primary">
              ({portStepData.startPort?.name || portStepData.startPort?.nameEN})
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item container direction="column" alignItems={"center"}>
        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            {getCountryCodeKR(portStepData.startViaPort?.country ?? "")}
            {portStepData.startViaPort?.type === "air" ? "공항" : "항"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            (
            {portStepData.startViaPort?.name ||
              portStepData.startViaPort?.nameEN}
            )
          </Typography>
        </Grid>
      </Grid>
    );
  }, [
    portStepData.incoterms,
    portStepData.serviceType,
    portStepData.startPort?.country,
    portStepData.startPort?.name,
    portStepData.startPort?.nameEN,
    portStepData.startPort?.type,
    portStepData.startViaPort,
  ]);

  const endPortIcon = useMemo(() => {
    if (
      ((portStepData.incoterms === "FOB" || portStepData.incoterms === "FCA") &&
        !portStepData.endAddress) ||
      ((portStepData.incoterms === "CIF" || portStepData.incoterms === "EXW") &&
        !portStepData.endViaPort)
    ) {
      return getPortIcon("endPort");
    }
    return getPortIcon("endViaPort");
  }, [
    portStepData.endAddress,
    portStepData.endViaPort,
    portStepData.incoterms,
    getPortIcon,
  ]);

  const endPortName = useMemo(() => {
    if (
      ((portStepData.incoterms === "FOB" || portStepData.incoterms === "FCA") &&
        !portStepData.endAddress) ||
      ((portStepData.incoterms === "CIF" || portStepData.incoterms === "EXW") &&
        !portStepData.endViaPort) ||
      (portStepData.incoterms === "CFR" && !portStepData.isImport)
    ) {
      return (
        <Grid item container direction="column" alignItems={"center"}>
          <Grid item>
            <Typography variant="body2" component="div" color="primary">
              {getCountryCodeKR(portStepData.endCountry)}
              {portStepData.endPort?.type === "air" ? "공항" : "항"}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" component="div" color="primary">
              ({portStepData.endPort?.name || portStepData.endPort?.nameEN})
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item container direction="column" alignItems={"center"}>
        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            {getCountryCodeKR(portStepData.endCountry)}
            {portStepData.endViaPort?.type === "air" ? "공항" : "항"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            ({portStepData.endViaPort?.name || portStepData.endViaPort?.nameEN})
          </Typography>
        </Grid>
      </Grid>
    );
  }, [
    portStepData.endAddress,
    portStepData.endCountry,
    portStepData.endPort?.name,
    portStepData.endPort?.nameEN,
    portStepData.endPort?.type,
    portStepData.endViaPort,
    portStepData.incoterms,
    portStepData.isImport,
  ]);

  const endAddressDetailName = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      if (portStepData.endAddressDetail) {
        return `${portStepData.endAddress}${portStepData.endAddressDetail}`;
      }
      return `${portStepData.zone?.name}${portStepData.endAddress}`;
    }

    if (portStepData.endAddress) {
      if (!portStepData.isImport) {
        return `${portStepData.endAddress}`;
      }
      return `${portStepData.endAddress}${portStepData.endAddressDetail}`;
    }
    return "-";
  }, [
    portStepData.endAddress,
    portStepData.endAddressDetail,
    portStepData.isImport,
    portStepData.serviceType,
    portStepData.zone?.name,
  ]);

  return {
    endAddressName,
    startAddressName,
    endAddressDetailName,
    startAddressDetailName,
    startPortIcon,
    endPortIcon,
    startPortName,
    endPortName,
    getStartAndEndAddressColor,
  };
}
